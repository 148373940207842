<template>
	<div>
		<v-dialog class="chat-haggle" v-model="is_show" persistent width="auto" fullscreen transition="slide-fade" hide-overlay light>
			<v-row no-gutters style="background: rgba(0, 0, 0, 0.4)">
				<v-col cols="12" lg="9" md="7" sm="6"></v-col>
				<v-col cols="11" lg="3" md="5" sm="6" style="height: 100vh; background: #ffffff; margin-left: auto" class="bid-box pa-0 d-flex flex-column">
					<div class="px-6 pb-6" style="position: relative">
						<v-row
							no-gutters
							class="pl-6 d-sm-flex align-center"
							style="height: 79px; border-bottom: 1px solid #e7eaf3; margin: 0px -24px 0px -24px"
						>
							<v-btn v-on:click="showChatIcon()" class="my-back-btn" depressed>
								<img :src="$store.state.icons.icons['LeftArrow']" class="back-btn" alt />
								Back
							</v-btn>
						</v-row>

						<v-row>
							<div class="chat-main-h1 pa-4">Haggle Chats</div>
						</v-row>

						<v-row>
							<div class="px-4 chat-search-box" style="width: 100%">
								<v-text-field
									single-line
									clearable
									hide-details
									solo
									:background-color="'#D3D9EA'"
									height="40"
									prepend-inner-icon="search"
									color="#CACEDA"
									type="text"
									placeholder="Search"
									v-model="searchUser"
								></v-text-field>
							</div>
						</v-row>

						<v-row class="chats-users">
							<v-col v-for="(user, index) in chatUsers" :key="index" cols="12" class="pa-0 px-4" @click="openUserChat(user.id)">
								<div class="px-4 user-list d-flex align-center">
									<div class="d-flex align-center justify-center">
										<img :src="require('../assets/images/' + user.id + '.png')" alt="" />
									</div>

									<div class="pl-4">
										<div class="user-name d-flex align-center">
											<div class="dot-icon mr-3"></div>
											{{ user.name }}
										</div>
										<div class="last-message">
											{{ user.lastMessage }}
										</div>
									</div>
								</div>
							</v-col>
						</v-row>
					</div>
				</v-col>
			</v-row>
		</v-dialog>

		<chat-message :is_show="show_user_chat" @openUsersList="openUsersList()"></chat-message>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
const ChatMessage = () => import('../mycomponents/ChatMessage.vue')
export default {
	props: {
		is_show: Boolean,
	},

	components: {
		ChatMessage,
	},

	data() {
		return {
			show_user_chat: false,
			searchUser: null,
			randomKey: 1,
		}
	},

	computed: {
		...mapGetters({
			users: 'getUserList',
		}),

		chatUsers() {
			if (this.searchUser) {
				return this.users.filter((item) => item.name.toUpperCase().includes(this.searchUser.toUpperCase()))
			} else {
				return this.users
			}
		},
	},

	methods: {
		showChatIcon() {
			this.$emit('showChatIcon')
			this.searchUser = null
		},

		openUserChat(id) {
			let user = this.users.find((user) => user.id == id)
			this.$store.dispatch('setChatUser', user)
			this.show_user_chat = true
			// this.$emit('showChatIcon')
		},

		openUsersList() {
			this.show_user_chat = false
			this.searchUser = null
		},
	},
}
</script>

<style lang="scss">
.chat-search-box .v-input__slot {
	background: #e0e8ed !important;
	border: none !important;
	border-radius: 4px !important;
	padding-left: 15px;
	min-height: 40px !important;
}

.chat-search-box .v-input__control > .v-input__slot:focus-within {
	background: #e0e8ed !important;
}

.chat-search-box .v-input__control > .v-input__slot {
	box-shadow: none !important;
}
</style>

<style lang="scss" scoped>
.chat-main-h1 {
	font-family: 'Inter-Medium';
	font-size: 24px;
}

.user-list {
	height: 72px !important;
	border-bottom: 1px solid #e0e8ed;
	cursor: pointer;

	img {
		height: 40px !important;
		width: 40px !important;
	}

	.dot-icon {
		content: '';
		height: 10px;
		width: 10px;
		border-radius: 10px;
		background: #2974ff;
	}

	.user-name {
		color: #212121;
		font-family: 'Inter-Regular';
		font-size: 15px;
	}

	.last-message {
		display: block;
		color: #7c8da6;
		font-family: 'Inter-Regular';
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 340px;
		font-size: 14px;
		line-height: 16px;
	}
}
.chats-users {
	max-height: calc(100vh - 220px);
	overflow-y: scroll;
}
</style>
